<template>
  <div class="verouillage-gloable-simulation">
    <b-button
      size="sm"
      class="button-export-style"
      title="
           Verrouillage des factures
            "
      @click.prevent="handleLockFacture"
    >
      <font-awesome-icon icon="lock"
    /></b-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    dataToUse: { required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(['lockGlobaleFactureSimulation']),
    async handleLockFacture() {
      this.$swal
        .fire({
          title:
            "Êtes-vous sur de vouloir modifier l'état de configuration de ces factures ",
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          allowOutsideClick: () => !this.$swal.isLoading(),
          stopKeydownPropagation: false,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          confirButtonColor: '#218838',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            let params = {
              ids: this.dataToUse
                .filter(item => item.check == true && item.facture_id)
                .map(i => i.facture_id),
              data: this.dataToUse.filter(item => item.check == true)
            };
            const response = await this.lockGlobaleFactureSimulation(params);
          }
        });
    }
  }
};
</script>
