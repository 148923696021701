<template>
  <div class="custom-editable-input-th">
    <div
      @dblclick="editProduct"
      class="show-value"
      :class="{ 'show-value-l': showValueL }"
    >
      {{ item[valueToShow] }}{{ uniteMontant
      }}<font-awesome-icon
        class="show-view-pencil ml-2"
        icon="pencil-alt"
        :class="{
          'show-editable-icon': showIconEdit == true && item.lock == false
        }"
        v-if="editable == true && item.lock == false && item.master == false"
      />
    </div>
    <!-- <div
      v-if="loaderUpdateLoch && showBlock"
      class="chargement chargement-loading-icon-lock"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <font-awesome-icon
      icon="lock-open"
      class="ml-1"
      v-if="item.lock == false && item.facture_id && showBlock"
      @click="handleLockFacture"
    />
    <font-awesome-icon
      icon="lock"
      class="ml-1"
      v-if="item.lock == true && item.facture_id && showBlock"
      @click="handleLockFacture"
    /> -->
    <b-modal
      no-close-on-backdrop
      title="Modifier list des produits"
      ref="listProductSimulation"
      id="listProductSimulation"
      :hide-footer="true"
      :hide-header="true"
      size="sm"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg custom-modal-template-w-60-p"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          {{ item.project_numero }} : {{ item.objet }} ({{ item.vendeur }})
        </div>
        <div
          class="iconClose col-1"
          @click.prevent="hideModal('listProductSimulation')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <!-- <div
        class="iconClose"
        @click.prevent="hideModal('listProductSimulation')"
      >
        <font-awesome-icon icon="times" />
      </div> -->
      <!-- <div class="message text-aligne-center">
        <div v-if="errorUpdate" class="error-message">
          <div class="error">
            {{ errorUpdate }}
          </div>
        </div>
      </div> -->
      <div
        class="sumilation-template-block-style"
        v-if="FactureToUpdate"
        id="hmt_block_id"
      >
        <form @submit.prevent="handleUpdate">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              type="submit"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div
              v-if="loadingUpdate == true"
              class="loading-custom-template-block"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
          <b-col class="col-12 block-product-simulation ">
            <b-row class="mb-3">
              <b-col class="col-5 text-center"> <b>Nom</b></b-col>
              <b-col class="col-2 text-center"><b> PU HT </b></b-col>
              <b-col class="col-2 text-center"><b>Unité</b></b-col>
              <b-col class="col-2 text-center"><b>Qté</b></b-col>
              <b-col class="col-1 "
                ><b>
                  <button
                    type="button"
                    class="btn-add-anay-prop mt-2 ml-0"
                    title="Ajouter un produit"
                    @click="handleAddNewProdict"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-ciston-config-dync"
                    /></button></b
              ></b-col>
            </b-row>
            <b-row
              class="fields-input"
              v-for="(product, index) in FactureToUpdate"
              :key="'product' + index"
            >
              <b-col class="col-5"
                ><b-form-group
                  label=""
                  label-for="title-produt"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="title-produt"
                    v-model="product.nom"
                    required
                  ></b-form-input> </b-form-group
              ></b-col>
              <b-col class="col-2"
                ><b-form-group
                  label=""
                  label-for="pu-ht"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="pu-ht"
                    v-model="product.pu_ht"
                    :disabled="product.unite != '' && product.id != null"
                  ></b-form-input> </b-form-group
              ></b-col>

              <b-col class="col-2">
                <b-form-group
                  label=""
                  label-for="unite-product"
                  class="input-modal-champ"
                >
                  <b-form-select
                    autocomplete="off"
                    id="filiale-name"
                    v-model="product.unite"
                    value-field="id"
                    text-field="name"
                    :options="listUnite"
                    class="b-form-select-raduis"
                    @change="
                      product.unite == ''
                        ? (product.qte = 1)
                        : (product.qt = product.qt)
                    "
                  ></b-form-select> </b-form-group></b-col
              ><b-col class="col-2">
                <b-form-group
                  label=""
                  label-for="qte-product"
                  class="input-modal-champ"
                >
                  <b-form-input
                    id="qte-product"
                    v-model="product.qte"
                    :disabled="product.unite == '' && product.id != null"
                  ></b-form-input> </b-form-group
              ></b-col>
              <b-col class="col-1">
                <b-button
                  class="button-danger-style btn-remove-anay-prop"
                  size="sm"
                  variant="danger"
                  @click="handleDeleteNewProdict(index)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
              </b-col>
              <hr class="separate-line col-10 m-0 mb-2 ml-4 " />
            </b-row>
            <div class="message text-aligne-center">
              <div v-if="errorUpdate" class="error-message">
                <div class="error">{{ errorUpdate }}</div>
              </div>
            </div>
          </b-col>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    value: {
      required: true
    },
    item: {
      required: false
    },
    widthFix: { default: false },
    champName: { required: true },
    updateFunction: { required: true },
    valueToShow: { required: true },
    editable: { default: true },
    showIconEdit: { default: false },
    uniteMontant: { default: '' },
    showValueL: {
      default: false
    },
    showBlock: { default: false }
  },
  data() {
    return {
      errorUpdate: null,
      loadingUpdate: false,
      FactureToUpdate: null,
      listUnite: [
        { id: '', name: '' },
        { id: '%', name: '%' }
      ],
      loaderUpdateLoch: false
    };
  },
  methods: {
    ...mapActions([
      'lockFactureSimulation',
      'generateFactureProformaSimulation'
    ]),
    cancel() {
      this.hideModal('listProductSimulation');
    },
    resetModal() {
      this.FactureToUpdate = null;
      this.loadingUpdate = false;
      this.errorUpdate = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleAddNewProdict() {
      this.FactureToUpdate.push({
        nom: null,
        pu_ht: 0,
        unite: '%',
        qte: 0
      });
    },
    handleDeleteNewProdict(index) {
      this.FactureToUpdate.splice(index, 1);
    },
    async handleUpdate() {
      this.errorUpdate = null;
      let params = {
        data: this.item,
        project_id: this.item.project_id,
        vendeur_id: this.item.vendeur_id,
        objet: this.item.objet,
        tva: this.item.tva,
        products: this.FactureToUpdate,
        client_id: this.item.client_id
      };
      if (this.item.facture_id) {
        params.id_facture = this.item.facture_id;
      }
      this.loadingUpdate = true;
      const response = await this.generateFactureProformaSimulation(params);
      if (response?.succes) {
        this.loadingUpdate = false;
        this.cancel();
      } else {
        this.errorUpdate = response.error;
        this.loadingUpdate = false;
      }
    },
    async handleLockFacture() {
      if (this.item.facture_id) {
        this.loaderUpdateLoch = true;
        await this.lockFactureSimulation(this.item);
        this.loaderUpdateLoch = false;
      }
    },
    editProduct() {
      if (this.item.lock == false && this.item.master == false) {
        this.FactureToUpdate = JSON.parse(JSON.stringify(this.item.products));
        this.$refs['listProductSimulation'].show();
      }
    }
  },
  computed: {
    ...mapGetters([])
  }
};
</script>

<style lang="scss" scoped>
.custom-editable-input-th {
  justify-content: end;
  display: flex;
  .edit-value {
    display: flex;
    .input {
      text-align: center;
      outline: 0;
      border: 1px solid #ded3d5;
      font-weight: 400;
      font-size: 12px;
      min-width: 55px;
      background-color: transparent;
      width: 100%;
      border-radius: 5px;
    }
    .width-fix {
      width: 100px;
    }
    .edit-action {
      .action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 19px;
        margin: 0 1px;
        margin-left: 1px;
        margin-right: 1px;
        border: 1px solid #ded3d5;
        font-size: 6px;
        cursor: pointer;

        &.validate {
          color: #4d4bac;
          width: 20px;
          height: 20px;
          margin-bottom: 2px;
          border-radius: 5px;
          &:hover {
            color: #ffffff;
            background-color: #4d4bac;
          }
        }

        &.cancel {
          color: #dc3c3c;
          border-radius: 5px;
          &:hover {
            color: #ffffff;
            background-color: #dc3c3c;
          }
        }
      }
    }
  }
  .show-value {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    justify-content: start;
    cursor: pointer;
    .show-view-pencil {
      color: #050505;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    .show-editable-icon {
      display: inline-block;
      opacity: 1;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
  .show-value-l {
    min-width: 82px;
  }
  .separate-line {
    border: 1px solid #ccc;
  }
}
.sumilation-template-block-style {
  .block-product-simulation {
    height: calc(100vh - 175px);
    overflow-y: auto;
  }
}
.chargement-loading-icon-lock {
  font-size: 8px;
  margin-left: 5px;
  .spinner-border {
    width: 0.75rem;
    height: 0.75rem;
  }
}
</style>
<style lang="scss">
.custom-modal-template-w-60-p .modal-dialog {
  width: 60%;
  margin: 5px auto;
}
</style>
