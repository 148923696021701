<template>
  <div class="similation-facture-frais">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2" v-if="checkPermission('SFTHFTDDDDF')">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Type de date</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-5-px"
            v-model="type_date"
            :options="[
              { value: 'statue_date', text: 'statut de paiement' },
              { value: 'date_depot_dossier', text: 'date de dépôt' }
            ]"
            text-field="text"
            value-field="value"
            @change="handleChangeTypeDate"
          ></b-form-select>
        </div>
        <filter-component
          v-if="checkPermission('SFTHFTDDDDF')"
          label="Date début"
          classLabel="label-box-style w-63-px"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              required
              :value="date_debut"
              @input="handleDateTimePicker($event, 'date_debut')"
              :disabled-date="disabledStartDate"
              input-class="custom-date-picker-filter"
              class="custom-date-picker-calender-filter"
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          v-if="checkPermission('SFTHFTDDDDF')"
          label="Date fin"
          classLabel="label-box-style w-46-px"
        >
          <template v-slot:body>
            <date-picker
              value-type="format"
              type="date"
              :value="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              :clear-button="true"
              :disabled-date="disabledEndDate"
              input-class="custom-date-picker-filter"
              class="custom-date-picker-calender-filter"
            ></date-picker
          ></template>
        </filter-component>

        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorSimulationFactureFrais" class="error">
            <ul
              v-if="Array.isArray(getErrorSimulationFactureFrais)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorSimulationFactureFrais"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSimulationFactureFrais }}</div>
          </div>
        </div>
        <div
          v-if="getLoadingSimulationFactureFrais"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <search
            v-if="checkPermission('SFTHCS')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
          />
          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Master F"
              v-if="checkPermission('SFTHFMF')"
              classLabel="w-126-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterMasterFiliale"
                  :options="[...getListMasterSimulationDync]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  @input="handleChangeMasterFiliale"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} masters</span
                    ></template
                  >
                </multiselect></template
              >
            </filter-component>

            <filter-component
              v-if="checkPermission('SFTHFV')"
              label="Société"
              classLabel="w-126-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterSupport"
                  :options="[
                    { name: 'MASTER FILIALE', id: 0 },
                    ...getListSupportSimulationDync
                  ]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  @input="handleChangeSupport"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Société'
                          : values.length > 1
                          ? ' Sociétés'
                          : ''
                      }}</span
                    ></template
                  >
                </multiselect></template
              >
            </filter-component>
            <filter-component
              v-if="checkPermission('SFTHFCL')"
              label="Client"
              classLabel="w-126-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterFiliale"
                  :options="[...getListInstallagteurSimulationDync]"
                  :multiple="true"
                  label="name"
                  track-by="id"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  @input="handleChangeFiliale"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length
                      }}{{
                        values.length == 1
                          ? ' Client'
                          : values.length > 1
                          ? ' Clients'
                          : ''
                      }}</span
                    ></template
                  >
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Famille"
              classLabel="w-126-px"
              v-if="checkPermission('SFTHFFS')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="filterFamilly"
                  track-by="id"
                  label="familly"
                  :options="getListFamilleConfigFrias"
                  :searchable="false"
                  class="customSelectSingleFilter mt-2 mb-2"
                  placeholder="Sélectionner"
                  :loading="getLoadingFamilleConfig"
                  @input="handleChangeFamille"
                  :showLabels="false"
                >
                </multiselect>
              </template>
            </filter-component>
            <filter-component
              v-if="checkPermission('SFTHFL')"
              label="Lot"
              classLabel="w-126-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="lots"
                  :options="[...getListLotsSimulationDync]"
                  :multiple="true"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :loading="getLoaderDataFilterListSimulationDyn"
                  @input="handleChangeLots"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} lots</span
                    ></template
                  >
                </multiselect></template
              >
            </filter-component>
            <filter-component
              classLabel="w-126-px"
              v-if="checkPermission('SFTHFCO')"
              label="Confirmation"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="filterConfirmation"
                  :options="[
                    { value: null, text: 'Tous' },
                    { value: 'oui', text: 'Oui' },
                    { value: 'non', text: 'Non' }
                  ]"
                  text-field="text"
                  value-field="value"
                  @change="handleChangeConfirmation"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              classLabel="w-126-px"
              v-if="checkPermission('SFTHFSF')"
              label="Statut"
            >
              <template v-slot:body>
                <multiselect
                  v-model="statut"
                  :options="listStatuts"
                  :multiple="true"
                  track-by="value"
                  label="name"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  @input="handleFilter"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length"
                      >{{ values.length }} Statuts</span
                    ></template
                  >
                </multiselect></template
              >
            </filter-component>
          </b-popover>
          <regenerer-frais-simulation
            v-if="checkPermission('SFTHRD')"
            :dataToUse="computedSimulationFactureFrais"
            @reloadData="handleFilter"
            :filterConfirmation="filterConfirmation"
            :filter="computedFilter"
          />
          <div class="mr-1">
            <list-dossier
              v-if="checkPermission('SFTHCDD')"
              :dataToUse="computedDataSimCheck"
            />
          </div>
          <div class="mr-1">
            <verouillage-globale
              v-if="computedDataCheck > 0 && checkPermission('SFTHMEC')"
              :dataToUse="computedSimulationFactureFrais"
            />
          </div>
          <div class="mr-1">
            <export
              v-if="checkPermission('SFTHSDX')"
              :filter="computedFilterExport"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          type_date !== null ||
            date_debut !== null ||
            date_fin !== null ||
            searchValue !== null ||
            filterConfirmation != null
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont: </span>
        <span class="item-filter-style" v-if="type_date !== null"
          >Type de date : {{ type_date | FormateFilter }}</span
        >
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début : {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin : {{ date_fin }}</span
        >
        <span v-if="filterConfirmation !== null" class="item-filter-style"
          >Confirmé :{{ filterConfirmation }}</span
        >
        <span
          v-if="searchValue !== null && searchValue !== ''"
          class="item-filter-style"
          >Recherche :{{ searchValue }}</span
        >
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation" id="chip-filters-simulation">
      <div v-if="filterMasterFiliale.length" class="block-filter">
        <span class="title-block-chip">Master : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterMasterFiliale"
          @input="removeAll('handleChangeMasterFiliale')"
        >
          <vs-chip
            :key="masterF.name + 'master-filiale'"
            @click="
              remove(
                masterF,
                'filterMasterFiliale',
                'handleChangeMasterFiliale'
              )
            "
            v-for="masterF in filterMasterFiliale"
            closable
          >
            {{ masterF.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterSupport && filterSupport.length" class="block-filter">
        <span class="title-block-chip">Société :</span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterSupport"
          @input="removeAll('handleChangeSupport')"
        >
          <vs-chip
            :key="support.name + 'support'"
            @click="remove(support, 'filterSupport', 'handleChangeSupport')"
            v-for="support in filterSupport"
            closable
          >
            {{ support.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterFiliale.length" class="block-filter">
        <span class="title-block-chip">Client :</span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="filterFiliale"
          @input="removeAll('handleChangeFiliale')"
        >
          <vs-chip
            :key="filiale.name + 'fil'"
            @click="remove(filiale, 'filterFiliale', 'handleChangeFiliale')"
            v-for="filiale in filterFiliale"
            closable
          >
            {{ filiale.name }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="filterFamilly" class="block-filter width-family-chip">
        <span class="title-block-chip">Famille : </span>
        <vs-chip
          v-model="filterFamilly"
          :key="filterFamilly.familly + 'famille'"
          @click="removeFamillyFilter()"
          closable
        >
          {{ filterFamilly.familly }}
        </vs-chip>
      </div>
      <div v-if="lots && lots.length" class="block-filter">
        <span class="title-block-chip">Lots :</span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="lots"
          @input="removeAll('handleChangeLots')"
        >
          <vs-chip
            :key="lot"
            @click="remove(lot, 'lots', 'handleChangeLots')"
            v-for="lot in lots"
            closable
          >
            {{ lot }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="statut.length" class="block-filter">
        <span class="title-block-chip">Statut : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="statut"
          @input="removeAll('handleChangeMasterFiliale')"
        >
          <vs-chip
            :key="stat.name + 'master-filiale'"
            @click="remove(masterF, 'statut', 'handleFilter')"
            v-for="stat in statut"
            closable
          >
            {{ stat.name }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="
            custom-table-style
            customTableSimulationFactureFrais
            table-header
          "
          :style="sizeTable"
          :items="computedSimulationFactureFraisTable"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:head()="data">
            <div class="text-uppercase">
              <div v-if="data.field.key == 'check_all'">
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
              <span v-else> {{ data.field.label }}</span>
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <!-- @click="checkAllVerif(data.item)" -->
            <template v-if="data.item.total">{{ data.item.count }}</template>
            <b-form-checkbox
              v-else
              v-model="data.item.check"
              class="check-th"
              :value="true"
              :unchecked-value="false"
              :disabled="data.item.facture == false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(numero_dossier)="data">
            <font-awesome-icon
              v-if="!data.item.total"
              icon="circle"
              :class="{
                'red-color-icon': data.item.project_confirmation == false,
                'green-color-icon': data.item.project_confirmation == true
              }"
            />
            {{ data.item.project_numero ? data.item.project_numero : '-' }}
          </template>
          <template v-slot:cell(nom_prenom)="data">
            {{ data.item.project_client ? data.item.project_client : '-' }}
          </template>
          <template v-slot:cell(project_montant_final)="data">
            {{
              data.item.project_montant_final
                ? data.item.project_montant_final
                : '-'
            }}
            €
          </template>
          <template v-slot:cell(montant_net_ht)="data">
            {{
              data.item.montant_net_ht
                ? data.item.montant_net_ht
                : '-'
            }}
            €
          </template>
          <template v-slot:cell(regie)="data">
            {{ data.item.project_regie ? data.item.project_regie : '-' }}
          </template>
          <template v-slot:cell(project_type_isolation)="data">
            {{
              data.item.project_type_isolation
                ? data.item.project_type_isolation
                : '-'
            }}
          </template>
          <template v-slot:cell(project_date_depot_dossier)="data">
            {{ data.item.project_date_depot_dossier | formateDate }}
          </template>
          <template v-slot:cell(project_statut_date)="data">
            {{ data.item.project_statut_date | formateDate }}
          </template>
          <template v-slot:cell(date_facture)="data">
            {{ data.item.date_facture | formateDate }}
          </template>
          <template v-slot:cell(project_installateur)="data">
            {{
              data.item.project_installateur
                ? data.item.project_installateur
                : '-'
            }}
          </template>
          <template v-slot:cell(ht)="data">
            <template v-if="data.item.total">{{ data.item.ht }} €</template>
            <column-frais-simulation
              v-else
              :showCoef="false"
              :item="data.item"
              column="ht"
              columnCoef="ht"
              :updateFunction="updateCoefFraisSimulation"
              :editable="false"
              :showBlock="true"
              :uniteMontant="data.item.facture == false ? '' : '€'"
            />
          </template>
          <template v-slot:cell(tva)="data">
            {{ data.item.tva ? data.item.tva : '-' }}
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type ? data.item.type : '-' }}
          </template>
          <template v-slot:cell(ttc)="data">
            <template v-if="data.item.total">{{ data.item.ttc }} €</template>
            <column-frais-simulation
              v-else
              :showCoef="false"
              :item="data.item"
              column="ttc"
              columnCoef="ttc"
              :updateFunction="updateCoefFraisSimulation"
              :editable="false"
              :showBlock="true"
              :uniteMontant="data.item.facture == false ? '' : '€'"
            />
          </template>
          <template v-slot:cell(coef)="data">
            <template v-if="data.item.total">-</template>
            <column-frais-simulation
              v-else
              :showCoef="true"
              :item="data.item"
              column="coef"
              columnCoef="coef"
              :updateFunction="updateCoefFraisSimulation"
              :editable="checkPermission('SFTHMC')"
              :showBlock="false"
              :uniteMontant="data.item.coef_type"
            />
          </template>
          <template v-slot:cell(num)="data"
            >{{ data.item.num ? data.item.num : '-' }}
            <custom-iframe
              v-if="!data.item.total"
              :getDataPDF="getDataPDFSimulation"
              :data="data.item"
            />
          </template>
          <template v-slot:cell(objet)="data">
            {{ data.item.objet ? data.item.objet : '-' }}</template
          >
          <template v-slot:cell(validation)="data">
            <div>
              <!-- <confirmation-unique
                :data="data"
                :id="data.item.id"
                :index="computedSimulationFactureFrais.length"
              /> -->
              {{ data.item.loc }}
              <font-awesome-icon
                icon="lock-open"
                class="ml-1"
                v-if="data.item.lock == false && data.item.facture_id"
              />
              <font-awesome-icon
                icon="lock"
                class="ml-1"
                v-if="data.item.lock == true && data.item.facture_id"
              />
            </div>
          </template>
          <!-- <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les dossiers"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les dossiers"
              />
            </button>
          </template> -->

          <!-- <template #row-details="data">
            <div v-if="data.item.loadingDetailt">
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </div>
            <template v-else-if="data.item.factures.length">
              <span
                v-for="(item, index) in data.item.factures"
                :key="data.item.numero_dossier + index"
                class="align-td-item border-botom background-week"
              >
                <td
                  class="color visibility-stiky-row-ssetails item-details-style"
                >
                  <div class="block-service-style">
                    -
                  </div>
                </td>
                <td
                  class="details-column-frais visibility-stiky-row-ssetails item-details-style"
                >
                  <div class="block-service-style">-</div>
                </td>
                <td
                  class="installeur-column-frais visibility-stiky-row-ssetails item-details-style"
                >
                  <div class="block-service-style ">-</div>
                </td>
                <td class="item-details-style">-</td>
                <td class="item-details-style">-</td>
                <td class="item-details-style">-</td>
                <td class="item-details-style">-</td>
                <td class="item-details-style">
                  <column-frais-simulation
                    :showCoef="showCoef"
                    :item="item"
                    :data="data.item"
                    column="ttc"
                    columnCoef="coef"
                    :updateFunction="updateCoefFraisSimulation"
                    :editable="true"
                  />
                </td>
                <td class="item-details-style ">
                  {{ item.objet }} ({{ item.vendeur }})
                </td>
                <td class="item-details-style ">
                  {{ item.num ? item.num : '-' }}
                </td>
                <td
                  class=" col-num-dossier-th-simulation visibility-stiky-row-ssetails item-details-style"
                >
                  -
                </td>
              </span></template
            >
            <template v-else>
              <span class="tr-vide"> Il n'y a aucune facture</span>

              <tr></tr
            ></template>
          </template> -->
        </b-table>
        <div class="footer-style">
          <b-pagination
            v-model="page"
            :total-rows="getSimulationTotalRow"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>
          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import columnFraisSimulation from './component/columnFraisSimulation.vue';
import confirmationUnique from './component/confirmationUnique.vue';
import verouillageGlobale from './component/verouillageGlobale.vue';
import moment from 'moment';
import RegenererFraisSimulation from './component/regenererFraisSimulation.vue';
import ConfirmedDossier from './component/confirmedDossier.vue';
import FilterComponent from '@/views/ThProject/frais/component/filterComponent.vue';

export default {
  name: 'Simulation-Facture-Frais',
  data() {
    return {
      listStatuts: [
        { value: 'Simulation', name: 'Simulation' },
        {
          value: 'Créé',
          name: 'Créé'
        },
        { value: 'A payer', name: 'A payer' },
        { value: 'Payé', name: 'Payée' },
        { value: 'Annulée', name: 'Annulée' },
        { value: 'Payé en partie', name: 'Payé en partie' }
      ],
      statut: [],
      filterFamilly: null,
      heigthBlock: 0,
      type_date: 'date_depot_dossier',
      date_debut: null,
      date_fin: null,
      filterMasterFiliale: [],
      filterSupport: [],
      filterFiliale: [],
      lots: [],
      page: 1,
      perPage: 20,
      perPageList: [
        { value: 20, text: 20 },
        { value: 100, text: 100 },
        { value: 200, text: 200 }
      ],
      showCoef: false,
      checkAll: false,
      filterConfirmation: null,

      searchValue: null
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingSimulationFactureFrais',
      'getSimulationFactureFraisData',
      'getErrorSimulationFactureFrais',
      'getSimulationTotalRow',
      'getLoaderDataFilterListSimulationDyn',
      'getListMasterSimulationDync',
      'getListSupportSimulationDync',
      'getListInstallagteurSimulationDync',
      'getListLotsSimulationDync',
      'checkPermission',
      'getListFamilleConfigFrias',
      'getLoadingFamilleConfig'
    ]),
    TopFilter() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 75 + heigthBlock;
      return 'top : ' + `${hei}` + 'px!important ;';
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterMasterFiliale?.length > 0 ||
        this.filterSupport?.length > 0 ||
        this.filterFiliale?.length > 0 ||
        this.lots?.length > 0 ||
        this.statut?.length > 0
      ) {
        return true;
      }
      return false;
    },
    formateValueFacture() {
      return function(data) {
        return data
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          .replaceAll(',', ' ');
      };
    },
    computedSimulationFactureFrais() {
      if (this.filterConfirmation != null) {
        let confirm = this.filterConfirmation == 'oui' ? true : false;
        return this.getSimulationFactureFraisData.filter(
          item => item.project_confirmation == confirm
        );
      }
      return this.getSimulationFactureFraisData;
    },
    computedDataCheck() {
      return this.computedSimulationFactureFraisTable.filter(
        item => item.check == true && item.facture_id
      ).length;
    },
    computedDataSimCheck() {
      return this.computedSimulationFactureFraisTable.filter(
        item => item.check == true
      );
    },
    computedSimulationFactureFraisTable() {
      let table = [];
      var somme = { ttc: 0, ht: 0, indexColor: 0, count: 0 };
      this.computedSimulationFactureFrais.map((item, index) => {
        if (
          index != 0 &&
          item.project_numero !=
            this.computedSimulationFactureFrais[index - 1].project_numero
        ) {
          let data = this.computedSimulationFactureFrais[index - 1];
          table.push({
            total: true,
            ht: this.formateValueFacture(Math.round(somme.ht * 100) / 100),
            ttc: this.formateValueFacture(Math.round(somme.ttc * 100) / 100),
            project_numero: data.project_numero,
            project_client: data.project_client,
            project_regie: data.project_regie,
            project_montant_final: data.project_montant_final,
            project_installateur: data.project_installateur,
            project_date_depot_dossier: data.project_date_depot_dossier,
            project_statut: data.project_statut,
            project_installateur: data.project_installateur,
            project_statut_date: data.project_statut_date,
            project_type_isolation: data.project_type_isolation,
            montant_net_ht: data.montant_net_ht,
            count: somme.count
          });
          somme = {
            ttc: 0,
            ht: 0,
            indexColor: somme.indexColor == 0 ? 1 : 0,
            count: 0
          };
        }
        if (item.facture) {
          somme.ht += parseFloat((item.ht_int + '').replace(' ', ''));
          somme.ttc += parseFloat((item.ttc_int + '').replace(' ', ''));
        }

        somme.count = somme.count + 1;
        item.index = somme.indexColor;
        table.push(item);
        if (index == this.computedSimulationFactureFrais.length - 1) {
          table.push({
            total: true,
            ht: this.formateValueFacture(Math.round(somme.ht * 100) / 100),
            ttc: this.formateValueFacture(Math.round(somme.ttc * 100) / 100),
            project_numero: item.project_numero,
            project_client: item.project_client,
            project_regie: item.project_regie,
            project_montant_final: item.project_montant_final,
            project_installateur: item.project_installateur,
            project_date_depot_dossier: item.project_date_depot_dossier,
            project_statut: item.project_statut,
            project_installateur: item.project_installateur,
            project_statut_date: item.project_statut_date,
            project_type_isolation: item.project_type_isolation,
            count: somme.count
          });
          somme = {
            ttc: 0,
            ht: 0,
            indexColor: somme.indexColor == 0 ? 1 : 0,
            count: 0
          };
        }
      });
      return table;
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          tdClass: 'color',
          thClass: 'th-color',
          show: true
        },
        // {
        //   key: 'details',
        //   label: '',
        //   tdClass: 'details-column-frais',
        //   thClass: 'th-details-column-frais',
        //   for: 'all'
        // },
        {
          key: 'numero_dossier',
          label: 'Numéro de dossier',
          show: true,
          thClass: 'th_stiky_num_simulation',
          tdClass: 'col_stiky_num_simulation'
        },

        {
          key: 'nom_prenom',
          label: 'Nom et Prénom',
          editable: false,
          show: this.checkPermission('SFTHCNEP')
        },
        {
          key: 'project_installateur',
          label: 'Filiale Installateur',
          show: this.checkPermission('SFTHACFI')
        },
        {
          key: 'regie',
          label: 'Régie',
          show: this.checkPermission('SFTHACR')
        },
        {
          key: 'project_type_isolation',
          label: 'Type isolation',
          show: this.checkPermission('SFTHACTI')
        },
        {
          key: 'project_montant_final',
          label: 'Montant min',
          editable: false,
          tdClass: 'montant-in-facture',
          show: this.checkPermission('SFTHACMM')
        },
        {
          key: 'montant_net_ht',
          label: 'Montant HT Dossier',
          editable: false,
          tdClass: 'montant-in-facture',
          show: this.checkPermission('SFTHACMHTD')
        },
        {
          key: 'project_date_depot_dossier',
          label: 'Date de dépôt',
          show: this.checkPermission('SFTHACDDD')
        },
        {
          key: 'project_statut',
          label: 'Statut de paiement',
          show: this.checkPermission('SFTHACSDP')
        },
        {
          key: 'project_statut_date',
          label: 'Date de paiement',
          show: this.checkPermission('SFTHACDDP')
        },
        {
          key: 'type',
          label: 'Type de document',
          show: this.checkPermission('SFTHACTDD')
        },
        {
          key: 'num',
          label: 'Numéro de facture',
          show: this.checkPermission('SFTHACNDF')
        },
        {
          key: 'vendeur',
          label: 'Société',
          show: this.checkPermission('SFTHACV')
        },
        {
          key: 'client',
          label: 'Client',
          show: this.checkPermission('SFTHACCL')
        },
        {
          key: 'objet',
          label: 'Objet',
          show: this.checkPermission('SFTHACOB')
        },
        {
          key: 'ht',
          label: 'Montant HT',
          tdClass: 'montant-in-facture',
          show: this.checkPermission('SFTHACMHT')
        },
        {
          key: 'tva',
          label: 'TVA',
          show: this.checkPermission('SFTHACTVA')
        },
        {
          key: 'ttc',
          label: 'Montant TTC',
          tdClass: 'montant-in-facture',
          show: this.checkPermission('SFTHACMTTC')
        },
        {
          key: 'coef',
          label: 'Unité',
          show: this.checkPermission('SFTHACCOEF')
        },
        {
          key: 'statue',
          label: 'Statut de facture',
          show: this.checkPermission('SFTHACSDF')
        },
        {
          key: 'date_facture',
          label: 'Date de création de la facture',
          show: this.checkPermission('SFTHACDDCDLF')
        },
        {
          key: 'validation',
          label: '',
          thClass: 'colhead-num-dossier-th-simulation  ',
          tdClass: ' col-num-dossier-th-simulation',
          show: this.checkPermission('SFTHACVER')
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedFilter() {
      let params = { type_date: this.type_date };

      if (this.date_debut != null) {
        params.date_debut = this.date_debut;
      }
      if (this.date_fin != null) {
        params.date_fin = this.date_fin;
      }
      if (this.filterMasterFiliale.length) {
        params.masters = this.filterMasterFiliale.map(item => item.id);
      }
      if (this.filterSupport?.length) {
        params.supports = this.filterSupport.map(item => item.id);
      }
      if (this.filterFiliale?.length) {
        params.clients = this.filterFiliale.map(item => item.id);
      }
      if (this.lots?.length) {
        params.lots = this.lots;
      }
      if (this.filterConfirmation != null) {
        params.confirmation = this.filterConfirmation;
      }
      if (this.filterFamilly != null) {
        params.support_familly = this.filterFamilly.id;
      }
      if (this.searchValue != null) {
        params.search = this.searchValue;
      }
      if (this.statut.length) {
        params.statut = this.statut.map(item => item.value);
      }
      return params;
    },
    computedFilterExport() {
      let params = new FormData();
      params.append('type_date', this.type_date);
      if (this.date_debut != null) {
        params.append('date_debut', this.date_debut);
      }
      if (this.date_fin != null) {
        params.append('date_fin', this.date_fin);
      }
      if (this.filterMasterFiliale.length) {
        this.filterMasterFiliale.forEach((item, index) => {
          params.append('masters[' + index + ']', item.id);
        });
      }
      if (this.filterSupport?.length) {
        this.filterSupport.forEach((item, index) => {
          params.append('supports[' + index + ']', item.id);
        });
      }
      if (this.filterFiliale?.length) {
        this.filterFiliale.forEach((item, index) => {
          params.append('clients[' + index + ']', item.id);
        });
      }
      if (this.lots?.length) {
        this.lots.forEach((item, index) => {
          params.append('lots[' + index + ']', item);
        });
      }
      if (this.filterConfirmation != null) {
        params.append('confirmation', this.filterConfirmation);
      }
      if (this.filterFamilly != null) {
        params.append('support_familly', this.filterFamilly.id);
      }
      if (this.statut.length) {
        this.statut.forEach((item, index) => {
          params.append('statut[' + index + ']', item.value);
        });
      }
      return params;
    }
  },
  filters: {
    FormateFilter: value => {
      switch (value) {
        case 'statue_date':
          return 'statut de paiement';
        case 'date_depot_dossier':
          return 'date de dépôt';
      }
    },
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  methods: {
    ...mapActions([
      'getAllSimulationFactureFrais',
      'updateCoefFraisSimulation',
      'confirmationDossierSimulation',
      'updateTauxFraisInInterface',
      'getAllDataForFilterDynamicSimulation',
      'getDataForRowSimulationDyncs',
      'getAllDossierForSimulationProjects',
      'getDataPDFSimulation',
      'fetchAllFammilleConfig'
    ]),
    handleLockFacture() {},
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    removeFamillyFilter() {
      this.filterFamilly = null;
      this.handleFilter();
    },
    removeAll(fct) {
      this[fct]();
    },
    getDataForRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        this.getDataForRowSimulationDyncs({
          item: data.item
          // supports: this.filterSupport
        });
      }
    },
    refreshFilter(refresh) {
      this.getAllDataForFilterDynamicSimulation({
        type_date: this.type_date,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        masters: this.filterMasterFiliale.map(item => item.id),
        confirmation: this.filterConfirmation,
        lots: this.lots,
        supports: this.filterSupport,
        refresh: refresh
      });
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    setLocalStorageSimulationFraisFacture() {
      localStorage.setItem(
        'simulation-frais-facture-dync',
        JSON.stringify({
          type_date: this.type_date,
          date_debut: this.date_debut,
          filterFamilly: this.filterFamilly,
          date_fin: this.date_fin,
          search: this.searchValue,
          filterFiliale: this.filterFiliale,
          filterMasterFiliale: this.filterMasterFiliale,
          filterSupport: this.filterSupport,
          confirmation: this.filterConfirmation,
          lots: this.lots,
          statut: this.statut
        })
      );
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      // this.filterFiliale = [];
      // this.filterMasterFiliale = [];
      // this.filterSupport = [];
      this.refreshFilter(['refreshClient', 'refreshMaster', 'refreshLots']);
      this.handleFilter();
    },
    handleChangeTypeDate() {
      // this.filterFiliale = [];
      // this.filterMasterFiliale = [];
      // this.lots = [];
      this.refreshFilter(['refreshClient', 'refreshMaster', 'refreshLots']);
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    handleChangeSupport() {
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.refreshFilter(['refreshLots', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeFamille() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeLots() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeConfirmation() {
      // this.filterFiliale = [];
      // this.filterMasterFiliale = [];
      // this.filterSupport = [];
      this.refreshFilter([]);
      this.handleFilter();
    },
    handleChangeMasterFiliale() {
      // this.filterFiliale = [];
      // this.filterSupport = [];
      this.refreshFilter(['refreshClient', 'refreshLots']);
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    handleFilter() {
      this.setLocalStorageSimulationFraisFacture();
      this.getAllSimulationFactureFrais({
        page: this.page,
        perPage: this.perPage,
        date_debut: this.date_debut,
        filterFamilly: this.filterFamilly,
        date_fin: this.date_fin,
        filterFiliale: this.filterFiliale,
        filterMasterFiliale: this.filterMasterFiliale,
        search: this.searchValue,
        support: this.filterSupport,
        confirmation: this.filterConfirmation,
        type_date: this.type_date,
        lots: this.lots,
        statut: this.statut
      });
      this.getAllDossierForSimulationProjects({
        page: this.page,
        perPage: this.perPage,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filterFiliale: this.filterFiliale,
        filterMasterFiliale: this.filterMasterFiliale,
        search: this.searchValue,
        support: this.filterSupport,
        confirmation: this.filterConfirmation,
        type_date: this.type_date,
        lots: this.lots,
        statut: this.statut
      });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.facture == false) return 'ligneYellow';

      if (item.total) return 'ligneLogo1';
      return 'ligneLogo3';
      // if (item.index == 0) return 'ligneLogo2';
      // if (item.index == 1) return 'ligneLogo3';
      // if (item.total) return 'ligneLogo1';
      // if (item.facture == false) return 'ligneYellow';
      // if (item.project_confirmation == true) return 'ligneAlert';
      // if (item.project_confirmation == false) return 'ligneAnomalie';
      // return 'ligneEnCourDeValidation';
    },
    checkAllFunction() {
      this.computedSimulationFactureFrais.map(item => {
        item.check = item.facture == false ? false : this.checkAll;
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    }
  },
  components: {
    listDossier: () => import('./component/listDossier.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    columnFraisSimulation,
    // deconfirmationDossierFrais: () =>
    //   import('./component/deconfirmationFraisDossier.vue'),
    // confirmationUnique,
    RegenererFraisSimulation,
    // ConfirmedDossier,
    FilterComponent,
    export: () => import('./component/export.vue'),
    verouillageGlobale,
    customIframe: () => import('@/views/component/customIframe')
  },
  async mounted() {
    if (localStorage.getItem('simulation-frais-facture-dync')) {
      let localVar = JSON.parse(
        localStorage.getItem('simulation-frais-facture-dync')
      );
      this.date_debut = localVar.date_debut;
      this.date_fin = localVar.date_fin;
      this.filterMasterFiliale = localVar.filterMasterFiliale;
      this.filterFiliale = localVar.filterFiliale;
      this.filterSupport = localVar.filterSupport;
      this.searchValue = localVar.search;
      this.filterConfirmation = localVar.confirmation;
      this.type_date = localVar.type_date;
      this.lots = localVar.lots;
      this.statut = localVar.statut ? localVar.statut : [];
    } else {
      var now = moment();
      this.date_debut = moment(now)
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.setLocalStorageSimulationFraisFacture();
    }
    this.refreshFilter([
      'refreshClient',
      'refreshLots',
      'refreshSupports',
      'refreshMaster'
    ]);
    this.fetchAllFammilleConfig({ blocked: false });
    this.handleFilter();
  }
};
</script>
<style scoped lang="scss">
.similation-facture-frais {
  .entete {
    padding: 5px 10px;
  }
  // .body-box-rapport-resize {
  // max-height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableSimulationFactureFrais {
      // max-height: calc(100vh - 220px) !important;
      // height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
    // .resizeTableSimulation {
    // max-height: calc(100vh - 220px) !important;
    // height: calc(100vh - 220px) !important;
    // }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
}
</style>
<style lang="scss">
.color {
  border-left: none !important;
}
.color {
  border-left: none !important;
}
.table-rapport-style .customTableSimulationFactureFrais td,
.customTableSimulationFactureFrais th {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}

.table-rapport-style .customTableSimulationFactureFrais {
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
    width: 107px;
    min-width: 107px;
  }
  .btn-anomalie {
    padding: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  .background-week {
    justify-content: start !important;
    // background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      // width: 100px;
      // min-width: 100px;
      padding: 8px 5px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }

    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff !important;
      display: table-cell;
    }
  }
}
.blok-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.visibility-stiky-row-ssetails {
  background: #ededed;
  border: none !important;
}
.tr-vide {
  line-height: 25px;
}

.width-family-chip {
  width: auto !important;
}
.col_stiky_num_simulation {
  position: sticky;
  left: 30px;
}
.th_stiky_num_simulation {
  position: sticky;
  left: 30px;
  z-index: 20 !important;
}
</style>
