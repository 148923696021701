import { render, staticRenderFns } from "./confirmedDossier.vue?vue&type=template&id=969f3968&scoped=true&"
import script from "./confirmedDossier.vue?vue&type=script&lang=js&"
export * from "./confirmedDossier.vue?vue&type=script&lang=js&"
import style0 from "./confirmedDossier.vue?vue&type=style&index=0&id=969f3968&prod&scoped=true&lang=scss&"
import style1 from "./confirmedDossier.vue?vue&type=style&index=1&id=969f3968&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "969f3968",
  null
  
)

export default component.exports