<template>
  <div class="confirmation-multiple">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      title="Confirmer les dossiers"
      @click.prevent="changeConfirmationDossier(true)"
    >
      <font-awesome-icon icon="check"
    /></b-button>
    <!-- CONFIRMATION Modal -->
    <b-modal
      ref="confirmation-dossier-facture-th-modal"
      id="confirmation-dossier-facture-th-modal"
      no-close-on-backdrop
      :hide-footer="true"
      :size="step == 0 ? 'm' : 'lg'"
      :title="'Confirmation des dossiers '"
      @hidden="hideModal('confirmation-dossier-facture-th-modal')"
      modal-class="modal-extaction-bdd-frais"
    >
      <template v-if="step == 0">
        <div>
          <div class="row">
            <div class="col-12">
              <b-form-group label="Type de document a généré : ">
                <b-form-radio
                  v-model="document"
                  name="some-radios"
                  value="facture"
                  >Facture</b-form-radio
                >
                <b-form-radio
                  v-model="document"
                  name="some-radios"
                  value="proforma"
                  >Proforma</b-form-radio
                >
              </b-form-group>
            </div>
            <!-- <div class="col-12">
              <b-form-group
                id="fieldset-headers-horizontal"
                label="Choisie les supports pour la génération automatique des factures: "
                label-for="confirmation-dossier-facture-th-modal"
              >
                <div
                  v-if="getloadingListSupports"
                  class="chargement-message-text"
                >
                  Chargement en cours du list support
                </div>
                <template v-else>
                  <b-form-checkbox
                    v-model="selectedAllSupport"
                    switch
                    @change="changeCheckSupport"
                    >Tous</b-form-checkbox
                  >

                  <b-form-checkbox-group
                    switches
                    stacked
                    text-field="name"
                    value-field="id"
                    id="checkbox-group-1"
                    v-model="selectedSupport"
                    :options="computedGetFilialsSupport"
                    name="flavour-1"
                  ></b-form-checkbox-group
                ></template>
              </b-form-group>
            </div> -->
          </div>
        </div>
        <div class="form-modal-custom-style mt-2">
          <div class="messageError text-aligne-center">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ error }}</span>
            </div>
          </div>
          <div class="actionModel">
            <b-button class="button-valide-style " @click="changeStatut()">
              <span>
                Générer
                <div v-if="loading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div></div
      ></template>

      <template v-else>
        <div>
          <b-button
            size="sm"
            variant="light"
            class="button-default-style ml-2 export-facture-auto"
            title="Télecharger  cette contenue"
            @click.prevent="downloadContentAutoFacture()"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
        </div>
        <div id="divFactureAuto" ref="divFactureAuto">
          <div
            v-for="(msg, index) in message"
            :key="msg.num_dossier"
            :class="{
              'card-generation-fact': index % 2 == 0,
              'card-generation-fact-active': index % 2 != 0
            }"
            style="break-inside: avoid;"
          >
            <center>
              <b class="mb-2">Numéro de dossier : {{ msg.num_dossier }}</b>
            </center>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.deja_exisit && msg.deja_exisit.length"
            >
              <div>
                <b class="title"
                  >Les supports suivant pour le dossier
                  {{ msg.num_dossier }}
                  ont déjà des factures :
                </b>
              </div>

              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.deja_exisit)">
                  <li v-for="(e, index) in msg.deja_exisit" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.empty_value && msg.empty_value.length"
            >
              <div>
                <b class="title"> Factures avec un montant égale à zéro : </b>
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.empty_value)">
                  <li v-for="(e, index) in msg.empty_value" :key="index">
                    <div>{{ e.objet }} ( {{ e.vendeur }} )</div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1"
              v-if="msg && msg.no_client && msg.no_client.length"
            >
              <div><b class="title">Client non trouvé : </b></div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.no_client)">
                  <li
                    v-for="(e, index) in msg.no_client"
                    :key="index"
                    class="li-simulation-conf"
                  >
                    <div>{{ e.objet }} ( {{ e.vendeur }} )</div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.no_config && msg.no_config.length"
            >
              <div>
                <b class="title">
                  Les supports suivants n'ont pas une configuration :
                </b>
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.no_config)">
                  <li v-for="(e, index) in msg.no_config" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="row m-1 d-block"
              v-if="
                msg && msg.no_or_multiple_rib && msg.no_or_multiple_rib.length
              "
            >
              <div>
                <b class="title">
                  Les supports suivants n'ont pas un RIB où à plusieurs RIB :</b
                >
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.no_or_multiple_rib)">
                  <li v-for="(e, index) in msg.no_or_multiple_rib" :key="index">
                    <div>{{ e.objet }} ( {{ e.vendeur }} )</div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row m-1 d-block"
              v-if="msg && msg.no_sequence && msg.no_sequence.length"
            >
              <div>
                <b class="title"> Des sociétes sans séquence :</b>
              </div>
              <div class="ml-3 body">
                <ul v-if="Array.isArray(msg.no_sequence)">
                  <li v-for="(e, index) in msg.no_sequence" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class=" m-1 d-block"
              v-if="msg && msg.generated && msg.generated.length"
            >
              <div><b class="title"> Les factures générées : </b></div>
              <div class=" body">
                <b-table-simple
                  v-if="Array.isArray(msg.generated)"
                  class="table-fiche m-0 p-0 d-flex justify-content-center"
                  responsive
                >
                  <b-tbody>
                    <b-tr class="title-ligne">
                      <b-th class="newcolor">Vendeur</b-th>
                      <b-th class="newcolor">Objet</b-th>
                      <b-th class="newcolor">Numéro Facture</b-th>
                      <b-th class="newcolor">Nombre des produits</b-th>
                      <b-th class="newcolor">MT TTC facture</b-th>

                      <b-th class="newcolor">Template</b-th>
                      <b-th class="newcolor">Nom client</b-th>
                    </b-tr>
                    <template v-for="(item, index) in msg.generated">
                      <b-tr :key="'genereted' + index">
                        <b-td>{{ item.vendeur }}</b-td>
                        <b-td>{{ item.objet }}</b-td>
                        <b-td>{{ item.num }}</b-td>

                        <b-td>{{ item.number_of_products }}</b-td>
                        <b-td>{{ item.montant_ttc }} €</b-td>

                        <b-td>{{ item.template }}</b-td>
                        <b-td>{{ item.client }}</b-td>
                      </b-tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <!-- END CONFIRMATION Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import html2pdf from 'html2pdf.js';
export default {
  props: {
    dataToUse: { required: true },
    filterConfirmation: { required: true }
  },
  data() {
    return {
      document: 'proforma',
      error: null,
      loading: false,
      listSupport: [
        { value: 'NOVAE', text: 'NOVAE' },
        { value: 'WEB ABONDAN', text: 'PINKMAN' },
        { value: 'ISO 33', text: 'KYNANE technique' },
        { value: 'RGE', text: 'CERTILIA' },
        { value: 'DNH', text: 'GE CDG' },
        { value: 'SAV-S', text: 'SAMI-B' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ACTEO', text: 'ALTEO France' },
        { value: 'alteo_control_gestion', text: 'Alteo contrôle qualité' },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'upcube', text: 'UPCUBE TECHNOLOGIES' },
        { value: 'LAV', text: 'LAV' },
        { value: 'AFSP', text: 'AFSP' },
        {
          value: 'AFCONFIRMATION',
          text: 'ALTEO FRANCE Régie Commerciale INTERNE'
        },
        { value: 'AFCOMMERCIAL', text: 'ALTEO FRANCE Support Commercial' },
        // { value: 'IHS', text: 'IHS' },
        { value: 'KYNAN - ISO 33 - ISAUDIT', text: "Bureau d'etude" },
        { value: 'BE B KYNAN', text: 'BE B KYNAN' },
        { value: 'BE B ALTEO', text: 'BE B ALTEO' },
        { value: 'MASTER FILIALE', text: 'MASTER FILIALE' }
      ],
      selectedSupport: [],
      selectedAllSupport: false,
      step: 0,
      message: null
    };
  },
  methods: {
    ...mapActions([
      'confirmationMultipleDossierSimulation',
      'getAllFilialSupport'
    ]),
    downloadContentAutoFacture() {
      html2pdf(this.$refs.divFactureAuto, {
        margin: 1,
        filename: 'rapport-facture.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      });
    },
    changeCheckSupport() {
      this.selectedSupport =
        this.selectedAllSupport == true
          ? this.computedGetFilialsSupport.map(item => {
              return item.id;
            })
          : [];
    },
    changeConfirmationDossier(show) {
      // this.getAllFilialSupport();
      var bodyFormData = new FormData();
      for (let j = 0; j < this.dataToUse.length; j++) {
        if (this.dataToUse[j].check == true) {
          bodyFormData.append('ids[' + [j] + ']', this.dataToUse[j].id);
        }
      }
      let testValue = null;
      for (var pair of bodyFormData.entries()) {
        if (pair[0] && pair[0].includes('ids')) {
          testValue = true;
        }
      }
      if (testValue == null) {
        this.error =
          "Sélectionne au moins un dossier afin d'effectuer un changement de l'etat.";
        this.$alert('', this.error, 'error').then(() => {
          this.error = null;
        });

        return;
      }
      if (show && this.filterConfirmation == 'non') {
        this.$refs['confirmation-dossier-facture-th-modal'].show();
      }
    },
    async changeStatut() {
      this.error = null;
      // if (this.selectedSupport.length == 0) {
      //   this.error = 'Sélectionne au moins un support';
      //   return;
      // } else {
      this.loading = true;
      var bodyFormData = new FormData();
      for (let j = 0; j < this.dataToUse.length; j++) {
        if (this.dataToUse[j].check == true) {
          bodyFormData.append('ids[' + [j] + ']', this.dataToUse[j].id);
        }
      }
      // for (let j = 0; j < this.selectedSupport.length; j++) {
      //   bodyFormData.append('supports[' + [j] + ']', this.selectedSupport[j]);
      // }
      let showModelDetails = this.selectedSupport.length ? true : false;
      const response = await this.confirmationMultipleDossierSimulation(
        bodyFormData
      );
      bodyFormData.append('document', this.document);

      if (response.succes) {
        this.loading = false;
        if (showModelDetails) {
          this.message = response.message.projects;
          this.step = 1;
        } else {
          this.hideModal('confirmation-dossier-facture-th-modal');
          let text = '<div ><div>Les  dossiers confirmés avec succès  </div>';
          this.$alert('', text, 'success').then(() => {
            this.$emit('reloadData');
          });
        }
      }
      // }
    },
    hideModal(ref) {
      if (this.step == 1) {
        this.$emit('reloadData');
      }
      this.resetModal();
      this.$refs[ref].hide();
    },
    resetModal() {
      this.error = null;
      this.loading = false;
      this.step = 0;
      this.selectedSupport = [];
      this.selectedAllSupport = false;
    }
  },
  computed: {
    ...mapGetters(['getFilialsSupport', 'getloadingListSupports']),
    computedGetFilialsSupport() {
      return [
        ...this.getFilialsSupport,
        { name: 'MASTER FILIALE', id: 'MASTER FILIALE' }
      ];
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: green;
  font-size: 14px;
  padding: 5px 7px 5px 9px;
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.card-generation-fact {
  border: 1px solid #e3e3e3;
  background-color: #f2f2f2;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.card-generation-fact-active {
  background-color: #f6f5fb;
  border: 1px solid #e0ddf0;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 7px;
  .title {
    font-size: 13px;
    color: #6958bd;
  }
  .body {
    font-size: 11px;
  }
}
.export-facture-auto {
  font-size: 17px;
  left: 89%;
  position: relative;
}
</style>
